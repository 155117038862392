<template>
  <b-row class="not-found text-center">
    <b-col class="mb-5">
      <NotFoundImg />
      <span>
        Something went wrong...
      </span>
    </b-col>
  </b-row>
</template>

<script>
import NotFoundImg from '@/components/svgs/404img.vue'

export default {
  name: '404',
  components: {
    NotFoundImg,
  },
}
</script>

<style lang="scss" scoped>
.not-found {
  background: #F8EEEC;
  display: flex;
  align-items: center;
  height: 100vh;
  font-weight: 800;
  font-size: 26px;
  line-height: 39px;
  color: #ee8b7b;
}
</style>
